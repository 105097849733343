.bt-admin, .bt-screen, .bt-player {
    background-color: #111;
    color: #eee;
    width: 100vw;
    height: 100vh;
    padding-top: 20px;
    box-sizing: border-box;
    font-family: 'Reem Kufi';
}

.bt-admin {
    font-size: 3em;
    text-align: center;
    padding-top:100px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.bt-waiting-player {
    width: 100%;
    height: 100%;
    font-size: 3em;
    text-align: center;
    padding-top:100px;
    color: #888;

}

.bt-screen h1, .bt-player h1 {
    text-align: center;
}
.bt-buzzer-holder {
    margin-top: 50px;
}

.bt-buzzer {
    width: 90%;
    height: 90%;
    margin:auto;
    position: relative;
    border-radius: 50%;
}

/* https://spin.atomicobject.com/2015/07/14/css-responsive-square/ */
.bt-buzzer:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.bt-buzzer-content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
}

.bt-buzzer:active {
    background-color: #450505;
    transition-property: background-color;
    transition-duration: 200ms;
    transition-delay: 0s;
}

.bt-buzzer:active .bt-buzzer-content {
    color: #fff;
    transition-property: color;
    transition-duration: 200ms;
    transition-delay: 0s;
}

.bt-buzzer-no-answer {
    box-shadow: inset 0 0 50px #ff4f4f;
    background-color: #630606;
    text-shadow: #ff4f4f 0 0 15px;
    color: #ffc4c4;
    transition-property: background-color;
    transition-duration: 200ms;
    transition-delay: 0s;
    font-size: 96px;
}

.bt-buzzer-answerer {
    box-shadow: inset 0 0 50px #00082c;
    background-color: rgb(29, 29, 83);
    border-radius: 50%;
    text-shadow: rgb(53, 53, 177) 0 0 15px;
    color: rgb(110, 110, 192);
    transition-property: background-color;
    transition-duration: 200ms;
    transition-delay: 0s;
    font-size: 72px;
}

.bt-buzzer-waiter {
    box-shadow: inset 0 0 50px #1b1b1b;
    background-color: rgb(97, 97, 97);
    color: #333333;
    transition-property: background-color;
    transition-duration: 200ms;
    transition-delay: 0s;
    font-size: 32px;
}

.bt-admin-correction {
    padding: 10px;
    width: 40vw;
    margin-top: 80px;
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
}

.bt-admin-correction-correct {
    background-color: rgb(5, 155, 5);
}

.bt-admin-correction-wrong {
    background-color: rgb(134, 6, 6);
}

.bt-screen {
    font-size: 2em;
    text-align: center;
    padding-top:100px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.bt-screen-main {
    flex: 1;
}

.bt-scores {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 1.5em;
}

.bt-score-value {
    color: rgb(216, 183, 0);
}

.bt-screen-answering {
    font-size: 2em;
}

.bt-tech-key {
    font-family: monospace;
    padding:0.5em;
}