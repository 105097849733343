.sideBySide {
    position: relative;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    box-sizing: border-box;
}
.side-text, .side-img {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.img-with-decoration {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.side-img-decoration {
    margin-top:10px;
    max-height: 130px;
}

@media(min-width: 1200px) {
    .sideBySide {
        width: 90vw;
        padding-left: 40px;
        padding-right: 40px;
    }

    .side-text, .side-img {
        width: 700px;
    }
    .side-img-main {
        width: 650px;
    }
}

@media(max-width: 1199px) {
    .sideBySide {
        width: 100vw;
        margin-top: 30px;
    }

    .side-text {
        padding-left: 20px;
        padding-right: 20px;
    }

    .side-img {
        width: 100vw;
    }
    .side-img-main {
        width: 100vw;
        margin: 0 !important;
    }
    .side-img-decoration {
        max-width: 80vw;
    }
    .side-text > div {
        padding: 60px 20px !important;
    }
    .side-text h2, .side-text h3 {
        margin-left: 0 !important;
        margin-right: 0 !important;
        text-align: center;
        letter-spacing: -2px;
    }
}
