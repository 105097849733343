

.separator {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
}

.separator-left, .separator-right {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--separator);
}

.separator-text {
    color: var(--separator);
    grid-row: 1 / 3;
    grid-column: 2;
    text-align: center;
    text-transform: uppercase;
    padding-left: 1em;
    padding-right: 1em;
}