.header {
    position: absolute;
    top: 0;
    width: 100vw;
    background-color: rgba(0, 0, 0, 80);
    color: white;
    font-size: 10px;
}

.user-name {

}

.user-table {
    color: rgb(219, 161, 0);
}