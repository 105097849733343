.countdown {
    margin: 10px;
    width: calc(var(--countdown-radius) * 2); 
    height: calc(var(--countdown-radius) * 2); 
    border-radius: var(--countdown-radius); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.countdown h2 {
    color: #fff;
    font-weight: bold;;
}


@media(max-width: 1199px) {

    .countdown {
        --countdown-radius: 13vw;
        margin: 0 -2vw 2vh -2vw;
        max-width: 115px;
        max-height: 115px;
        min-width: 75px;
        min-height: 75px;
    }
    .countdown h2{
        font-size: 36pt;
    }
}
@media(max-width: 375px) {

    .countdown h2{
        font-size: 28pt;
    }
}