.timeline-container {
    width: 100vw;
    overflow-x: auto;
}

.timeline {
    display: flex;
    --size: 40px;
    --space: 15px;
    --radius: calc(var(--size) * 0.5 + var(--space));
    justify-content: center;
    align-items: flex-start;
    padding: 30px;
    background-color: #222;
    min-width: 1200px;
}

.timeline-text {
    color: #aaa !important;
}

.timeline-icon-and-text {
    width: calc(var(--size) + 2 * var(--space) + 6px);
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.timeline-text {
    margin: 15px 0;
    width: calc(4 * var(--size) - 30px);
    font-size: 16px;
    font-style: italic;
    line-height: 1.3em;
}

.timeline-date {
    font-family: 'Amatic SC';
    font-weight: bold;
    font-size: 26px;
    letter-spacing: 1px;;
    width: calc(2 * var(--size));
    line-height: 30px;
    margin: 15px 0;
}

.timeline-icon {
    width: var(--size);
    height: var(--size);
    border-radius: var(--radius);
    border: 2px solid;
    padding: var(--space);
    margin-right: 4px;
}

.timeline-dots {
    height: 4px;
    line-height: 4px;
    font-size: 16px;
    letter-spacing: 3px;
    margin-top: calc(60px + var(--radius));
}

.timeline > :nth-child(1) .timeline-icon {
    border-color: var(--brown);
}
.timeline > :nth-child(2), 
.timeline > :nth-child(1) {
    color: var(--brown);
    /*filter: brightness(1.2);*/
}

.timeline > :nth-child(3) .timeline-icon,
.timeline > :nth-child(5) .timeline-icon {
    border-color: var(--raspberry);
}
.timeline > :nth-child(4), 
.timeline > :nth-child(3), 
.timeline > :nth-child(5) {
    color: var(--raspberry);
}

.timeline > :nth-child(8),
.timeline > :nth-child(6), 
.timeline > :nth-child(7) {
    color: var(--orange);
}
.timeline > :nth-child(7) .timeline-icon {
    border-color: var(--orange);
}

.timeline > :nth-child(10), 
.timeline > :nth-child(9), 
.timeline > :nth-child(11){
    color: var(--yellow);
}
.timeline > :nth-child(9) .timeline-icon,
.timeline > :nth-child(11) .timeline-icon {
    border-color: var(--yellow);
}

.timeline > :nth-child(13) .timeline-icon {
    border-color: var(--green);
}
.timeline > :nth-child(13), 
.timeline > :nth-child(12) {
    color: var(--green);
}

.timeline img {
    display: block;
    width: var(--size);
    height: var(--size);
}


@media(max-width: 1199px) {

    .timeline {
        justify-content: flex-start;
    }
}
