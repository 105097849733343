.trombinoscope {
    max-width: 1200px;
    margin: auto;
    color: var(--dark-brown);
    font-family: 'Roboto';
    font-size: 13pt;
    line-height: 1.7em;
}

.trombinoscope h3 {
    text-align: center;
    font-size: 40pt;
    color: var(--raspberry);
    font-weight: normal;
    margin-top:20px;
    margin-bottom: 20px;
    font-family: 'Amatic SC';
    line-height: 1.1em;
}

.trombinoscope-list {
    display: flex;
    flex-wrap: wrap;
}

.trombinoscope-item {
    background-color: #ddd;
    width: 180px;
    padding: 5px;
    height: 350px;
    margin: 10px;
}

.user-email {
    font-size: 0.6em;
    line-height: 0.6em;
}

.user-lastname::before {
    content: ' ';
}