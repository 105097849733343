
.quizz {
    font-family: 'Roboto';
}

.quizz h1 {
    font-family: 'Amatic SC';
}

.answer-correct {
    color: rgb(17, 138, 44);
}

.answer-wrong {
    color: rgb(124, 19, 19);
}

.answers-count {
    margin-left: 2em;
}

.quizz-correction-text {
    flex: 1;
}

.quizz-correction-correct, .quizz-correction-current-correct {
    color: rgb(17, 138, 44);
    padding: 2px;
}

.quizz-correction-current-correct {
    font-weight: bold;
    background-color: rgb(4, 53, 14);
}

.quizz-correction-other {
    color: #ccc;
    padding: 2px;
}

.quizz-correction-wrong, .quizz-correction-current-wrong {
    color: rgb(124, 19, 19);
    padding: 2px;
}

.quizz-correction-current-wrong {
    font-weight: bold;
    background-color: rgb(58, 5, 5);
}