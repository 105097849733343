
.onboarding {
    display: grid;
    grid-template-columns: 100px 150px 150px 100px 550px;
    grid-auto-rows: 40px;
    align-items: center;
}

.onboard-form {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.onboarded {
    opacity: 0.3;
}